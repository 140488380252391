import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from '../index';
import { useCourseTableColumns, ColumnType } from './useCourseTableColumns';
import { useGetCourses } from '../../hooks/ReactQuery/Courses/useGetCourses';
import useQueryParams from '../../helpers/useQueryParams';
import { useGetAllSubcategories } from '../../hooks/ReactQuery/Subcategories/useGetAllSubcategories';
import { useGetAllSources } from '../../hooks/ReactQuery/Sources/useGetAllSources';

const isFreeOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Free' },
  { value: 'false', label: 'Paid' },
];

const isShownOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Shown' },
  { value: 'false', label: 'Not shown' },
];

const languageOptions = [
  { value: '', label: 'All languages' },
  { value: 'english', label: 'English' },
  { value: 'russian', label: 'Russian' },
];

const isReadyOptions = [
  { value: '', label: 'All courses' },
  { value: 'true', label: 'Ready' },
  { value: 'false', label: 'Not ready' },
];

const Courses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedParams = new URLSearchParams(searchParams);
  const updateSearchParams = (key: string, value: string) => {
    if (value) {
      updatedParams.set(key, value); // Set searchValue parameter
    } else {
      updatedParams.delete(key); // Remove parameter if input is empty
    }
    setSearchParams(updatedParams);
  };

  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');
  const searchValueFromUrl = query.get('searchValue');
  const isShownFromUrl = query.get('isShown');
  const isFreeFromUrl = query.get('isFree');
  const languageFromUrl = query.get('language');
  const isReadyFromUrl = query.get('isReady');
  const sourceFromUrl = query.get('source');
  const subcategoryFromUrl = query.get('subcategory');

  const { data: subcategoriesData } = useGetAllSubcategories();
  const { data: sourcesData } = useGetAllSources();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>(searchValueFromUrl || '');
  const [isShown, setIsShown] = useState<string>(isShownFromUrl || '');
  const [isFree, setIsFree] = useState<string>(isFreeFromUrl || '');
  const [language, seLanguage] = useState<string>(languageFromUrl || '');
  const [isReady, seIsReady] = useState<string>(isReadyFromUrl || '');

  const [sourceOptions, setSourceOptions] = useState<any[]>([]);
  const [source, setSource] = useState<any>(sourceFromUrl || '');

  const [subcategoryOptions, setSubcategoryOptions] = useState<any[]>([]);
  const [subcategory, setSubcategory] = useState<any>(subcategoryFromUrl || '');

  const isFreeHandler = (e: any) => {
    setIsFree(e.value);
  };

  const isShownHandler = (e: any) => {
    setIsShown(e.value);
  };

  const languageHandler = (e: any) => {
    seLanguage(e.value);
  };

  const isReadyHandler = (e: any) => {
    seIsReady(e.value);
  };

  const subcategoryHandler = (e: any) => {
    setSubcategory(e.value);
  };

  const sourceHandler = (e: any) => {
    setSource(e.value);
  };

  const { data, error, isLoading } = useGetCourses(
    pageSize,
    currentPage,
    searchValue,
    isFree,
    isShown,
    language,
    isReady,
    subcategory,
    source
  );

  useEffect(() => {
    if (subcategoriesData) {
      const option = [{ value: '', label: 'All subcategory' }];
      subcategoriesData?.subcategories?.forEach((subcategoryItem: any) => {
        option.push({ value: subcategoryItem.uuid, label: subcategoryItem.nameEn });
      });
      setSubcategoryOptions(option);
    }
  }, [subcategoriesData, subcategoriesData?.subcategories]);

  useEffect(() => {
    if (sourcesData) {
      const option = [{ value: '', label: 'All source' }];
      sourcesData?.sources?.forEach((sourceItem: any) => {
        option.push({ value: sourceItem.uuid, label: sourceItem.nameEn });
      });
      setSourceOptions(option);
    }
  }, [sourcesData]);

  useEffect(() => {
    updateSearchParams('searchValue', searchValue);
  }, [searchValue]);

  useEffect(() => {
    updateSearchParams('currentPage', currentPage.toString());
  }, [currentPage]);

  useEffect(() => {
    updateSearchParams('isShown', isShown.toString());
  }, [isShown]);

  useEffect(() => {
    updateSearchParams('isFree', isFree.toString());
  }, [isFree]);

  useEffect(() => {
    updateSearchParams('language', language);
  }, [language]);

  useEffect(() => {
    updateSearchParams('isReady', isReady.toString());
  }, [isReady]);

  useEffect(() => {
    updateSearchParams('source', source);
  }, [source]);

  useEffect(() => {
    updateSearchParams('subcategory', subcategory);
  }, [subcategory]);

  const formattedData: ColumnType[] = data?.courses?.map((elem: any) => ({
    id: elem?.uuid,
    image: elem?.image,
    zipFile: elem?.zipFile,
    titleEn: elem?.titleEn,
    folderName: elem?.folderName,
    titleRu: elem?.titleRu,
    duration: elem?.duration,
    numberOfLessons: elem?.numberOfLessons,
    languageUuid: elem?.languageUuid,
    language: elem?.language?.nameEn,
    releaseDate: elem?.releaseDate,
    createdAt: elem?.createdAt,
    updateDate: elem?.updateDate,
    rating: elem?.rating,
    isFree: elem?.isFree,
    chLink: elem?.chLink,
    originalLink: elem?.originalLink,
    isShown: elem?.isShown,
  }));

  if (isLoading) {
    return <div>Loading...</div>; // Add a loading state
  }

  if (error) {
    return <div>Error loading courses</div>; // Handle error state
  }

  return (
    <Page
      title="Courses"
      description="Courses info"
      columns={useCourseTableColumns}
      formattedData={formattedData}
      searchKey="titleEn"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
      isShown={isShown}
      isShownHandler={isShownHandler}
      isShownOptions={isShownOptions}
      isFree={isFree}
      isFreeHandler={isFreeHandler}
      isFreeOptions={isFreeOptions}
      language={language}
      languageHandler={languageHandler}
      languageOptions={languageOptions}
      isReady={isReady}
      isReadyHandler={isReadyHandler}
      isReadyOptions={isReadyOptions}
      subcategoryOptions={subcategoryOptions}
      subcategoryHandler={subcategoryHandler}
      subcategory={subcategory}
      sourceOptions={sourceOptions}
      sourceHandler={sourceHandler}
      source={source}
    />
  );
};

export default Courses;
