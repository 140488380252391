import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';

export type ColumnType = {
  image: string;
  paymentSystemType: string;
  isShown: string;
  paymentUrl: string;
  order: number;
};

export const usePaymentSystemTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'order', header: 'Order' },
  { accessorKey: 'image', header: 'image' },
  { accessorKey: 'paymentSystemType', header: 'Payment System Type' },
  { accessorKey: 'paymentUrl', header: 'Payment url' },
  { accessorKey: 'isShown', header: 'isShown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'paymentSystem'} />,
  },
];
