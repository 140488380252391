import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import { FormCreator } from '../../components';
import { courseApi } from '../../api/services/coursesApi';
import { courseUpdateValidation, courseValidation } from './courseValidation';
import { useCourseCreatorFields } from './useCourseCreatorFields';
import useOptions from '../../hooks/useOptions';
import { languageApi } from '../../api/services/languageApi';
import { changeMultiSelectOptions } from '../../helpers/changeMultiSelectOptions';

interface optionType {
  value: string;
  label: string;
}

const CoursesNew: React.FC = () => {
  const queryClient = useQueryClient();

  const [subcategoryOptions, setSubcategoryOptions] = useState<optionType[]>([]);
  const [sourcesOptions, setSourcesOptions] = useState<optionType[]>([]);

  const allSubcategories: any = queryClient.getQueryData(['allSubcategories']);
  const allSources: any = queryClient.getQueryData(['allSources']);

  useEffect(() => {
    allSubcategories?.subcategories?.forEach((subcategor: any) => {
      setSubcategoryOptions((prevState) => [
        ...prevState,
        { value: subcategor?.uuid, label: subcategor?.nameEn },
      ]);
    });
  }, [allSubcategories]);

  useEffect(() => {
    allSources?.sources?.forEach((source: any) => {
      setSourcesOptions((prevState) => [
        ...prevState,
        { value: source?.uuid, label: source?.nameEn },
      ]);
    });
  }, [allSources]);

  const { options: languageOptions } = useOptions('languages', languageApi.getAllLanguages);
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const releaseDate = data?.releaseDate
    ? moment(new Date(data?.releaseDate)).format('MM/DD/YYYY')
    : null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const updateDate = data?.updateDate
    ? moment(new Date(data?.updateDate)).format('MM/DD/YYYY')
    : null;

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { course },
        } = await courseApi.getCourseByUuid(uuid);

        setData(course);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Courses"
        description="Create a courses"
        validation={data ? courseUpdateValidation : courseValidation}
        fields={useCourseCreatorFields({ sourcesOptions, subcategoryOptions, languageOptions })}
        submitFunction={data ? courseApi.updateCourse : courseApi.createCourse}
        initialData={
          data
            ? {
                ...data,
                sources: changeMultiSelectOptions(data?.sources),
                subcategories: changeMultiSelectOptions(data?.subcategories),
                releaseDate: releaseDate ? new Date(releaseDate) : null,
                updateDate: updateDate ? new Date(updateDate) : null,
              }
            : null
        }
      />
    )
  );
};

export default CoursesNew;
