import React, { useState } from 'react';

import { Page } from '../index';
import { usePaymentSystemTableColumns, ColumnType } from './usePaymentSystemTableColumns';
import Loading from '../../components/Loading/Loading';
import useQueryParams from '../../helpers/useQueryParams';
import { useGetPaymentSystem } from '../../hooks/ReactQuery/PaymentSystem/useGetPaymentSystem';
import { useGetAllPaymentProviders } from '../../hooks/ReactQuery/PaymentProvider/useGetAllPaymentProviders';

const PaymentSystem = () => {
  const query = useQueryParams();
  const currentPageFromUrl = query.get('currentPage');

  useGetAllPaymentProviders();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromUrl ? +currentPageFromUrl : 1
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching, isPending } = useGetPaymentSystem(pageSize, currentPage, searchValue);

  if (isFetching && isPending) return <Loading />;

  const formattedData: ColumnType[] = data?.paymentSystems?.map((elem: any) => ({
    id: elem?.uuid,
    image: elem?.image,
    paymentSystemType: elem?.paymentSystemType,
    paymentUrl: elem?.paymentUrl,
    isShown: elem?.isShown,
    order: elem?.order,
  }));

  return (
    <Page
      title="Payment System"
      description="Payment system info"
      columns={usePaymentSystemTableColumns}
      formattedData={formattedData}
      searchKey="paymentSystemType"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      dataTotalCount={data?.totalCount || 0}
      pageSize={pageSize}
      setSearchValue={setSearchValue}
    />
  );
};

export default PaymentSystem;
