export const pageContentEn = {
  COMPANY_NAME: 'SkillHunt',
  ALL_CATEGORIES: 'All categories',
  ALL_CHANNELS: 'All channels',
  SEE_MORE: 'See more',
  TO_CONTINUE_TO_SKILLHUNT: 'to continue to SkillHunt',
  SIGN_IN: 'Sign in',
  SIGN_UP: 'Sign up',
  SIGN_OUT: 'Sign out',
  REGISTRATION_COMPLETED_SUCCESSFULLY: 'Registration completed successfully',
  RESET_PASSWORD: 'Reset password',
  CREATE_YOUR_ACCOUNT: 'Create your account',
  CONTINUE_WITH_Facebook: 'Continue with Facebook',
  CONTINUE_WITH_Google: 'Continue with Google',
  OR: 'or',
  CONFIRM_PASSWORD: 'Confirm Password',
  PASSWORD: 'Password',
  EMAIL: 'Email',
  TITLE: 'Title',
  ORIGINAL_LINK: 'Original link',
  ENTER_EMAIL: 'Enter email',
  ENTER_TITLE: 'Enter title',
  ENTER_ORIGINAL_LINK: 'Enter original link',
  ENTER_PASSWORD: 'Enter password',
  ENTER_CONFIRM_PASSWORD: 'Enter confirm password',
  ENTER_LAST_NAME: 'Enter last name',
  LAST_NAME: 'Last name',
  ENTER_FIRST_NAME: 'Enter first name',
  FIRST_NAME: 'First name',
  HAVE_AN_ACCOUNT: 'Have an account?',
  FORGET_PASSWORD: 'Forget password?',
  RESET: 'Reset',
  NO_ACCOUNT: 'No account?',
  PREPARING: 'Preparing...',
  CONFIRM: 'Confirm',
  SAVE: 'save',
  PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
  REMEMBER_ME: 'Remember me',
  REQUEST: 'Request',
  EMAIL_SENT_NOTIFICATION: 'The email was sent successfully, please check your email.',
  SOMETHING_WENT_WRONG: 'Something went wrong, Please try later.',
  SOURCE: 'Source',
  CATEGORY: 'Category',
  LANGUAGE: 'Language',
  DURATION: 'Duration',
  NUMBER_OF_LESSONS: 'Number of lessons',
  RELEASE_DATE: 'Release date',
  UPDATE_DATE: 'Update date',
  ADDED_DATE: 'Added date',
  RATING: 'Rating',
  RATINGS: 'Ratings',
  LIKE: 'Like',
  DISLIKE: 'Dislike',
  VIDEOS: 'videos',
  CABINET: 'Cabinet',
  PRICING: 'Pricing',
  FAVORITES: 'Favorites',
  FAVORITE_COURSES: 'Favorite courses',
  FAVORITE_BOOKS: 'Favorite books',
  HISTORY: 'History',
  ORDER_DESK: 'Order desk',
  MY_ORDERS: 'My orders',
  SHOW_LESS: 'Show less',
  SHOW_MORE: 'Show more',
  GO_HOME: 'Go Home',
  SEE_ALL_COURSES: 'See all courses',
  ALL_COURSES: 'All courses',
  ALL_BOOKS: 'All books',
  MATCH: 'match',
  SEARCH_COURSES: 'Search courses',
  USER_INFORMATION: 'User Information',
  PLEASE_RATE_THE_COURSE: 'Please rate the course',
  PLEASE_RATE_THE_BOOK: 'Please rate the book',
  YOUR_RATING_IS: 'Your rating is',
  POPULAR_COURSES: 'Popular courses',
  LAST_ADDED: 'Last added',
  LAST_ADDED_BOOKS: 'Last added books',
  PAGES: 'pages',
  WE_CAN_NOT_FIND_WHAT_YOU_WANT: "We can't find what you want",
  ADD_ORDER: 'Add order',
  VIEW: 'View',
  NO_ORDERS_FOUND: 'No orders found',
  PURCHASE_HISTORY: 'Purchase history',
  GO_TO_PURCHASE: 'Go to purchase',
  FREE: 'Free',
  MONTHLY: 'Monthly',
  SIX_MONTHS: 'Six months',
  YEAR: 'Year',
  FREE_FOREVER: 'Free forever',
  FULL_ACCESS_MONTH: 'Full access for a month',
  FULL_ACCESS_SIX_MONTHS: 'Full access for six months',
  FULL_ACCESS_YEAR: 'Full access for a year',
  GET_STARTED: 'Get started',
  GO_UNLIMITED: 'Go unlimited',
  FREE_VIDEOS: 'Watch free videos',
  PAID_VIDEOS: 'Watch paid videos',
  FREE_BOOKS: 'Read free books',
  PAID_BOOKS: 'Read paid books',
  ORDERING_COURSES: 'Ordering courses',
  NO_CONTENT: 'No Content',
  BOOK_SHORT_DESCRIPTION: 'Book short description',
  VIDEO_COURSES: 'Video courses',
  BOOKS: 'Books',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  PRICE: 'Price',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  ORDER_DELETED_SUCCESSFULLY: 'Order deleted successfully',
  ABOUT_DELETED_SUCCESSFULLY: 'About deleted successfully',
  TERMS_CONDITIONS_DELETED_SUCCESSFULLY: 'Terms conditions deleted successfully',
  PRIVACY_POLICY_DELETED_SUCCESSFULLY: 'Privacy policy deleted successfully',
  NAVIGATION_DELETED_SUCCESSFULLY: 'Navigation deleted successfully',
  CATEGORY_DELETED_SUCCESSFULLY: 'Category deleted successfully',
  BOOK_CATEGORY_DELETED_SUCCESSFULLY: 'Book category deleted successfully',
  BOOK_SUBCATEGORY_DELETED_SUCCESSFULLY: 'Book subcategory deleted successfully',
  BOOK_DELETED_SUCCESSFULLY: 'Book deleted successfully',
  SUBCATEGORY_DELETED_SUCCESSFULLY: 'Subcategory deleted successfully',
  POSITION_STATUS_DELETED_SUCCESSFULLY: 'Position status deleted successfully',
  LANGUAGE_DELETED_SUCCESSFULLY: 'Language deleted successfully',
  USER_LANGUAGE_DELETED_SUCCESSFULLY: 'User language deleted successfully',
  SOURCE_DELETED_SUCCESSFULLY: 'Source deleted successfully',
  COURSE_DELETED_SUCCESSFULLY: 'Course deleted successfully',
  COURSE_INFORMATION_UPDATED_SUCCESSFULLY: 'Course information updated successfully',
  SWIPER_SLIDER_DELETED_SUCCESSFULLY: 'Swiper slider deleted successfully',
  PRICE_DELETED_SUCCESSFULLY: 'Price deleted successfully',
  USER_DELETED_SUCCESSFULLY: 'User deleted successfully',
  VISITS_DELETED_SUCCESSFULLY: 'Visits deleted successfully',
};
