import instance from '../axios';

export const booksApi = {
  getAllBooks: async (pageSize, currentPage, searchValue) =>
    instance.get('/v1/book/get-all-admin', { params: { pageSize, currentPage, searchValue } }),
  getBookByUuid: async (uuid) => instance.get(`/v1/book/get-by-uuid/${uuid}`),
  createBook: async (data) => instance.post('/v1/book/create', data),
  updateBook: async (uuid, data) => instance.put(`/v1/book/update/${uuid}`, data),
  deleteBook: async (uuid) => instance.delete(`/v1/book/delete/${uuid}`),
};
