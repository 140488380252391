import axios from 'axios';

import { ToastNotifyError } from '../helpers/toastNotify';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 4011) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem('sb_admin_token');
      // eslint-disable-next-line no-undef
      localStorage.removeItem('sb_admin_user');
      // eslint-disable-next-line no-undef
      sessionStorage.removeItem('sb_admin_token');
      // eslint-disable-next-line no-undef
      sessionStorage.removeItem('sb_admin_user');
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
    ToastNotifyError('error', error?.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    const token =
      // eslint-disable-next-line no-undef
      localStorage.getItem('sb_admin_token') || sessionStorage.getItem('sb_admin_token');
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const formDataInstanceCreator = async (url, data, method = 'post') =>
  instance[method](url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const formDataInstance = {
  post: async (url, data) => formDataInstanceCreator(url, data),
  put: async (url, data) => formDataInstanceCreator(url, data, 'put'),
};

export default instance;
